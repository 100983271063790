// import router from "@/router";
import memberService from "@/services/memberService";
// import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    members: [],
    loading: false,
  },
  getters: {
    getMembers: (state) => state.members,
  },
  mutations: {
    SET_MEMBERS(state, members) {
      state.members = members;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initMembers({ commit }) {
      try {
        const response = await memberService.getAllMembers();

        if (response.status === "success") {
          commit("SET_MEMBERS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },

    async getMemberById(_, memberId = null) {
      try {
        const response = await memberService.getMemberById(memberId);
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    async deleteMember({ commit }, memberId) {
      try {
        commit("SET_LOADING", true);
        const response = await memberService.deleteMember(memberId);

        if (response.status === "success") {
          commit(
            "SET_MEMBERS",
            this.state.member.members.filter(
              (member) => member.id !== memberId
            )
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

    // async createService({ commit }, service) {
    //   try {
    //     commit("SET_LOADING", true);

    //     const body = {
    //       name: service.name,
    //       content: service.content,
    //       en_name: service.en_name,
    //       en_content: service.en_content,
    //       image: service.image,
    //     };

    //     const response = await memberService.createService(body);

    //     if (response.status === "success") {
    //       notify({
    //         type: "success",
    //         text: response.message,
    //       });
    //       router.push({ name: "service-list-page" });
    //     }

    //     commit("SET_LOADING", false);
    //   } catch (error) {
    //     console.log(error.response.data.error);
    //     notify({
    //       type: "error",
    //       title: "Error",
    //       text: error.response.data.error,
    //     });
    //   }
    // },
    
    // async initService(_, serviceId) {
    //   try {
    //     const response = await memberService.getServiceById(serviceId);
    //     return response.data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // async updateService(_, service) {
    //   try {
    //     const body = {
    //       name: service.name,
    //       content: service.content,
    //       en_name: service.en_name,
    //       en_content: service.en_content,
    //       image: service.image,
    //     };

    //     const response = await memberService.updateService(service.id, body);

    //     if (response.status === "success") {
    //       notify({
    //         type: "success",
    //         text: response.message,
    //       });
    //       router.push({ name: "service-list-page" });
    //     }
    //   } catch (error) {
    //     console.log(error.response.data.error);
    //     notify({
    //       type: "error",
    //       title: "Error",
    //       text: error.response.data.error,
    //     });
    //   }
    // },
    // async uploadCategoryImage(_, image) {
    //   try {
    //     let formData = new FormData();
    //     for (var i = 0; i < image.length; i++) {
    //       let file = image[i];
    //       formData.append("images", file);
    //     }
    //     const response = await memberService.uploadCategoryImage(formData);
    //     return response.data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // async uploadServiceImage(_, image) {
    //   try {
    //     let formData = new FormData();
    //     for (var i = 0; i < image.length; i++) {
    //       let file = image[i];
    //       formData.append("images", file);
    //     }
    //     const response = await memberService.uploadServiceImage(formData);
    //     return response.data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
};
