import { createStore } from 'vuex'
import authModule from './modules/auth'
import memberModule from './modules/member'
import subscribersModule from './modules/subscribers'

export default createStore({
  modules: {
    auth: authModule,
    member: memberModule,
    subscribers: subscribersModule
  }
})
