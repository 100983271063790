<template>
  <app-bar :drawer="drawer" @updateDrawerValue="updateDrawer"></app-bar>
  <v-navigation-drawer v-model="drawer" style="z-index: 99999;">
    <div class="navbar-logo-container">
      <img
        src="../assets/images/logo.png"
        alt=""
        class="navbar-logo"
      />
    </div>
    <v-list density="compact" nav>
      <v-list-item
        prepend-icon="mdi-account-group-outline"
        title="Members"
        value="members"
        @click="navigateTo('/member')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-email-multiple-outline"
        title="Subscribers"
        value="subscribers"
        @click="navigateTo('/subscribers')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-information-outline"
        title="About"
        value="about"
        :disabled="true"
        @click="navigateTo('/about')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-human-greeting-proximity"
        title="Contact"
        value="contact"
        :disabled="true"
        @click="navigateTo('/contact')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-cog-outline"
        title="Settings"
        value="setting"
        :disabled="true"
        @click="navigateTo('/setting')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-format-list-group"
        title="Logs"
        value="log"
        :disabled="true"
        @click="navigateTo('/log')"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import AppBar from './AppBar.vue';
export default {
  name: "navigation-side",
  components: {
    AppBar,
  },
  data: () => ({
    drawer: true,
  }),
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    updateDrawer(value) {
      this.drawer = value;
    },
  },
};
</script>
