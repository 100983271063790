export default [
  {
    path: "/member",
    name: "list-member",
    component: () => import("../views/member/list-member-page.vue"),
  },
  {
    path: "/member/:id",
    name: "detail-member",
    component: () => import("../views/member/detail-member-page.vue"),
    props: true
  },
  {
    path: "/create-member",
    name: "create-member",
    component: () => import("../views/member/create-member-page.vue"),
  },
  {
    path: "/update-member/:id",
    name: "update-member",
    component: () => import("../views/member/update-member-page.vue"),
    props: true
  }
];
