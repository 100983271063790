<template>
  <v-layout class="rounded rounded-md">
    <NavigationSide />

    <v-main
      class="d-flex align-center justify-center"
      style="min-height: 300px"
    >
      <div class="home-wrapper">
        <router-view></router-view>
      </div>
    </v-main>
  </v-layout>
</template>
<script>
import NavigationSide from "@/components/NavigationSide.vue";

export default {
  name: "home-view",
  components: {
    NavigationSide,
  },

  data() {
    return {
      msg: "Welcome to Your Vue.js App",
    };
  },

  // created() {
  //   this.$router.push({ name: "user-list-page" });
  // },
};
</script>
