import memberRoutes from "./member-routes.js";

const childRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import( "../views/dashboard/dashboard-page.vue"),
  },
  {
    path: "/subscribers",
    name: "subscribers",
    component: () => import( "../views/subscribers/subscribers-page.vue"),
  },
  ...memberRoutes,
];

export default childRoutes;
