<template>
  <div>
    <notifications :duration="10000" :closeOnClick="true" />
    <div v-if="isLoading">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      isLoading: false,
    }
  },

  created() {
    this.initAuth().then(() => {
      this.isLoading = true;
    }).catch(() => {
      this.isLoading = true;
    });
  },

  methods: {
    ...mapActions({
      initAuth: 'auth/initAuth',
    }),
  },
}
</script>
<style lang="scss">
@import "~@/assets/scss/style.scss";
</style>