import { appAxios } from "./appService";

export default {
  async getAllMembers() {
    const response = await appAxios.get("/get-members");
    return response.data;
  },
  async createService(body) {
    const response = await appAxios.post("/admin/create-new-service", body);
    return response.data;
  },
  async deleteMember(memberId) {
    const response = await appAxios.delete(`/admin/delete-member/${memberId}`);
    return response.data;
  },
  async updateService(serviceId, body) {
    const response = await appAxios.put(`/admin/update-service/${serviceId}`, body);
    return response.data;
  },
  async getMemberById(memberId) {
    const response = await appAxios.get(`/get-member/${memberId}`);
    return response.data;
  },
};
