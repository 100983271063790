// import router from "@/router";
import subscribersService from "@/services/subscribersService";
// import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    subscribers: [],
    loading: false,
  },
  getters: {
    getSubscribers: (state) => state.subscribers,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_SUBSCRIBERS(state, subscribers) {
      state.subscribers = subscribers;
    },
  },
  actions: {
    async initSubscribers({ commit }) {
      try {
        const response = await subscribersService.getAllSubscribers();

        if (response.status === "success") {
          commit("SET_SUBSCRIBERS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
  },
};
