import { appAxios } from "./appService";

export default {
  async register(payload) {
    const body = {
      name: payload.fullname, // maybe fullname
      email: payload.email,
      username: payload.username,
      password: payload.password,
    };

    const response = await appAxios.post("/register-new-user", body);
    return response.data;
  },

  async login(payload) {
    const body = {
      username: payload.username,
      password: payload.password,
    };

    const response = await appAxios.post("/login", body);
    return response.data;
  },

  async verifyRegisterUser(payload) {
    const body = {
      phone: payload.phone,
      code: payload.code,
    };

    const response = await appAxios.post("/verify-register", body);
    return response.data;
  },

  async verifyUser() {
    // const body = {
    //   token: payload,
    // };

    const response = await appAxios.get("/admin/verify-user");
    return response.data;
  },
  async logout() {
    const response = await appAxios.post("/admin/logout");
    return response.data;
  },
  async updateProfileRequest() {
    const response = await appAxios.post("/change-password-request");
    return response.data;
  },
  async checkVerificationCode(body) {
    const response = await appAxios.post("/check-change-password-code", body);
    return response.data;
  },
  async updateUserInfos(body) {
    const response = await appAxios.put("/update", body);
    return response.data;
  },
  async uploadImage(formData) {
    const response = await appAxios.post("/profile-photo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },
};
