<template>
  <v-app-bar color="#F2F2F2" class="flex-grow-0">
    <v-app-bar-nav-icon
      @click="$emit('updateDrawerValue', !drawer)"
      v-if="!drawer"
    ></v-app-bar-nav-icon>

    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 30px;
      "
    >
      {{ user.name }}
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-title @click="doAction(item.value)" class="cursor-pointer">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "app-bar",
  props: {
    drawer: Boolean,
  },
  data: () => ({
    items: [
      { title: "Profile", icon: "mdi-account", value: "profile" },
      { title: "Logout", icon: "mdi-logout", value: "logout" },
    ],
  }),
  computed: {
    ...mapGetters({ user: "auth/getUser" }),
  },
  methods: {
    ...mapActions({ doSignOut: "auth/doSignOut" }),
    doAction(value) {
      switch (value) {
        case "profile":
          this.$router.push("/profile");
          break;
        case "logout":
          this.doSignOut();
          break;
      }
    },
  },
};
</script>
